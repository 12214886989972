import { ColumnConfig } from "@ant-design/charts";
import { Column, G2 } from "@ant-design/plots";
import { Empty } from "antd";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import formatCurrency from "../../utils/formatCurrency";
import { InitialValues } from "./types";

const { registerTheme } = G2;

// TODO: Discover if passing defaultColor is working
// Otherwise create a custom colour pallete to each provider

registerTheme("azure", { colors10: ["#4096ff"] });
registerTheme("azuredw", { colors10: ["#4096ff"] });
registerTheme("gcp", { colors10: ["#00B96B"] });
registerTheme("aws", { colors10: ["#FF9900"] });
registerTheme("ibm", { colors10: ["#001141"] });

const StackedBarChart = (override: ColumnConfig) => {
    const formik = useFormikContext<InitialValues>();

    const { t, i18n } = useTranslation();
    const config: ColumnConfig = {
        autoFit: true,
        appendPadding: [20, 0, 0, 0],
        label: {
            position: "top",
            formatter: function (data) {
                return `${formatCurrency(
                    formik.values.settings.currencyCode,
                    data.value,
                    i18n.language,
                )}`;
            },
            layout: [{ type: "interval-hide-overlap" }],
        },
        legend: {
            layout: "horizontal",
            position: "top",
        },
        slider: {
            start: 0,
            end: 1,
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.type,
                    value: formatCurrency(
                        formik.values.settings.currencyCode,
                        data.value,
                        i18n.language,
                    ),
                };
            },
        },
        ...override,
        theme: override.theme,
    };
    const chart = useMemo(() => {
        if (override.data.length > 0) {
            return <Column {...config} />;
        } else {
            return (
                <Empty
                    style={{
                        height: 400,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                    description={t("shared.noData")}
                />
            );
        }
    }, [JSON.stringify(override.data)]);

    return chart;
};

export default StackedBarChart;
